import React from 'react';
import { Layout } from 'components';
import 'normalize.css';

/**
 * @param element current page element
 * @param props gatsby injected props such as location
 * @description Used for wrapping all pages inside a shared layout. Layout component renders the content partially inside the <main /> html tag.
 */
export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
);
