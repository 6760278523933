import React from 'react';

import { Header } from 'components';
import { PageProps } from 'gatsby';
import { layoutStyles } from './Layout.styles';
import { Footer } from 'components/Footer';
import { margin } from '@prototyp/gatsby-plugin-gumball/utils';

export const Layout: React.FC<PageProps> = ({ children }) => {
  return (
    <div css={layoutStyles.root}>
      <Header />
      <div css={margin.bottom.xxxlrg}>{children}</div>
      <Footer />
    </div>
  );
};
