import { css } from '@emotion/react';

const medium = css`
  width: 100%;
  padding: 0 calc(var(--unit) * 9);
  margin: 0 auto;
  max-width: 968px;
`;

const small = css`
  width: 100%;
  padding: 0 calc(var(--unit) * 9);
  margin: 0 auto;
  max-width: 440px;
`;

const large = css`
  width: 100%;
  padding: 0 calc(var(--unit) * 9);
  margin: 0 auto;
  max-width: 1400px;
  min-width: 768px;
`;

export const wrapper = {
  small,
  medium,
  large,
};
