import { css } from '@emotion/react';
import { color } from '@prototyp/gatsby-plugin-gumball';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';

import { borderRadius, breakpoints, colors } from 'style/variables';
import { wrapper } from 'style/wrappers.styles';

const root = css`
  width: 100%;
  background-color: ${colors.backgroundLight};
  padding: calc(var(--unit) * 3) 0;
  position: relative;

  @media ${breakpoints.medium} {
    padding: calc(var(--unit) * 6) 0;
  }
`;

const content = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${wrapper.small}

  @media ${breakpoints.medium} {
    justify-content: space-between;
    flex-direction: row;
    ${wrapper.large}
  }
`;

const link = css`
  text-decoration: none;
  color: ${colors.textLight};
  ${font.size.medium};

  &:hover {
    color: ${color('primary', 5, 0.5)};
  }
`;

const activeLink = css`
  color: ${colors.primary};
`;

const logo = css`
  background-color: ${colors.backgroundLight};
  width: 200px;
  height: 200px;

  @media ${breakpoints.medium} {
    position: absolute;
    top: 5%;
    border-radius: ${borderRadius.full};
    width: 150px;
    height: 150px;
  }
`;

export const headerStyles = {
  root,
  content,
  link,
  activeLink,
  logo,
};
