import React from 'react';
import { Helmet } from 'react-helmet';
import seoImage from 'assets/images/seo-image.png';

interface Props {
  title: string;
  description?: string;
  imagePath?: string | null;
  href?: string;
}

export const SEO: React.FC<Props> = ({
  title,
  description,
  imagePath = seoImage,
  href,
}) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imagePath} />
    </Helmet>

    {description && (
      <Helmet>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
      </Helmet>
    )}

    {href && (
      <Helmet>
        <link rel="canonical" href={href} />
        <link rel="og:url" href={href} />
      </Helmet>
    )}
  </>
);
