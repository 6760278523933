import { GumGridConfig } from '@prototyp/gatsby-plugin-gumball/models';

import { GRID_SIGN_IN } from './grid.config';

/**
 * Unitless unit value (in px)
 */
export const unit = 4;

/**
 * Color HEX values (to be converted to HSL)
 */
export const colors = {
  primary: '#ff3b3f',
  secondary: '#caebf2',
  background: '#efefef',
  backgroundLight: '#ffffff',
  text: '#2b2b2b',
  textLight: '#a9a9a9',
};

/**
 * Unitless px value pairs [font-size, line-height]
 */
export const typography = {
  tiny: [12, 16],
  small: [14, 20],
  base: [16, 24],
  medium: [20, 28],
  large: [24, 36],
  xLarge: [32, 40],
  xxLarge: [40, 48],
  xxxLarge: [48, 56],
  huge: [64, 80],
};

/**
 * Minimum and maximum container widths, userd for fluid typography.
 * Max value is the total width of the page design (outer width of the page).
 */
export const minContainerWidth = 320;
export const maxContainerWidth = 1920;

export const fontWeight = {
  regular: 400,
  semiBold: 500,
  bold: 600,
  black: 700,
};

export const borderRadius = {
  regular: 'calc(var(--unit) * 2)',
  full: '50%',
};

export const fontFamily = {
  sansSerif: 'Manrope, sans-serif',
};

/**
 * Unitless z-index values, ordered from low to high
 */
export const zIndices = [-1, 0, 1, 2, 3];

/**
 * Breakpoint values without @media tags
 */

export const breakpoints = {
  contact: 'screen and (min-width: 600px)',
  small: 'screen and (min-width: 490px)',
  medium: 'screen and (min-width: 890px)',
  large: 'screen and (min-width: 1280px)',
  xLarge: 'screen and (min-width: 1920px)',
  grid: 'screen and (min-width: 1090px)',
  print: 'print',
};

/**
 * Spacing - margins, paddings, grid gaps
 */
export const spacing = {
  tny: 'calc(var(--unit) * 1)',
  sml: 'calc(var(--unit) * 2)',
  med: 'calc(var(--unit) * 4)',
  lrg: 'calc(var(--unit) * 6)',
  xlrg: 'calc(var(--unit) * 8)',
  xxlrg: 'calc(var(--unit) * 10)',
  xxxlrg: 'calc(var(--unit) * 12)',
  huge: 'calc(var(--unit) * 15)',
};

/**
 * Main grid - page layout
 */

/**
 * TUTORIAL
 * Page grid that designes have used (in Figma) is a standard 12-column grid with 32px gap.
 * This is desktop only, on tablets and lower resolutions it breaks into single column.
 */

export const pageGrid: GumGridConfig[] = [
  {
    template: { column: 'auto', row: 'auto' },
    gap: { row: 32 },
  },
  {
    mediaMinWidth: 'large',
    template: { row: 'auto', column: 'repeat(12, 1fr)' },
    gap: { row: 0, column: 32 },
  },
];

/**
 * TUTORIAL
 * Additional grid config that is dynamically generated.
 */
export const componentGrids = {
  signInForm: GRID_SIGN_IN,
};

export const customPalette = [];
