import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { colors } from './variables';

export const global = css`
  html {
    overflow-y: scroll;
    background: ${colors.background};
  }
  button {
    padding: 0;
    margin: 0;
  }
  select {
    width: 100%;
  }
  img {
    height: 100%;
  }
  html,
  body {
    min-height: 100vh;
    background: ${colors.background};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  ul,
  ol,
  dl {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
    padding: 0;
  }
  body {
    ${font.size.base}
    ${colors.text}
  }
  /**
   * TUTORIAL
   * Styles for async font loading
   * https://css-tricks.com/how-to-load-fonts-in-a-way-that-fights-fout-and-makes-lighthouse-happy/
   */
  body:not(.wf-poppins--loaded) {
    font-family: 'Big Shoulders Display', sans-serif;
  }

  .wf-poppins--loaded {
    font-family: var(--font-family-sansSerif);
  }
  p {
    margin: 0;
  }
`;
