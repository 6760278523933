import { font, margin, text } from '@prototyp/gatsby-plugin-gumball/utils';
import React from 'react';
import { footerStyles } from './Footer.styles';

import FacebookIcon from 'assets/images/facebook.svg';
import InstagramIcon from 'assets/images/instagram.svg';
import YoutubeIcon from 'assets/images/youtube.svg';
import DogIcon from 'assets/images/dog.svg';

export const Footer: React.FC = () => {
  return (
    <footer css={footerStyles.root}>
      <div>
        <a
          css={[footerStyles.email, margin.bottom.med, font.size.medium]}
          href={'mailto:info@ddmoto.hr'}
        >
          info@ddmoto.hr
        </a>

        <div css={footerStyles.descriptionContainer}>
          <p>DD Moto obrt</p>
          <p>Reisnerova 138, 31000 Osijek</p>
          <p>
            Kontakt <span css={font.weight.bold}>+385 (0)95 529 5829</span>
          </p>
          <p>Hrvatska</p>
        </div>
      </div>
      <div css={footerStyles.infoContainer}>
        <p>Radno vrijeme:</p>
        <p css={font.weight.bold}>PONEDJELJAK - PETAK</p>
        <p css={font.weight.bold}>10:00 - 15:00</p>
        <p css={margin.bottom.med}>ili po prethodnom telefonskom dogovoru</p>
        <p css={font.weight.black}>
          Subota, nedjelja, blagdani -{' '}
          <span css={text.color.primary}>NE RADIMO</span>
        </p>
      </div>
      <div css={footerStyles.socialContent}>
        <a
          css={footerStyles.socialIcon}
          href="https://www.facebook.com/SkuterCentarOsijek1"
        >
          <FacebookIcon css={footerStyles.socialIcon} />
        </a>
        <a
          css={footerStyles.socialIcon}
          href="https://www.instagram.com/dario.dumancic/"
        >
          <InstagramIcon css={footerStyles.socialIcon} />
        </a>
        <a
          css={footerStyles.socialIcon}
          href="https://www.youtube.com/channel/UC1tsk5pBdV20nrHEi8UuGPw"
        >
          <YoutubeIcon css={footerStyles.socialIcon} />
        </a>
        <a
          css={footerStyles.socialIcon}
          href="https://www.njuskalo.hr/trgovina/ddmoto"
        >
          <DogIcon css={footerStyles.socialIcon} />
        </a>
      </div>
    </footer>
  );
};
