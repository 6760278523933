/**
 * TUTORIAL
 * Optional file in which we can store individual grid configs.
 * In this case, we want to create vertical grid with gaps,
 * instead of assigning bottom margin to individual components.
 */

export const GRID_SIGN_IN = [
  {
    template: { column: 'auto', row: 'auto 1fr' },
    gap: { row: 32 },
  },
];
