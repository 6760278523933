import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { Link } from 'gatsby';
import React from 'react';
import Logo from 'assets/images/logo.svg';
import { headerStyles } from './Header.styles';

export const Header: React.FC = () => {
  return (
    <header css={headerStyles.root}>
      <div css={headerStyles.content}>
        <div>
          <Logo css={headerStyles.logo} />
        </div>
        <div>
          <Link css={[headerStyles.link, margin.right.lrg]} to="/">
            početna
          </Link>
          <Link css={headerStyles.link} to="/contact">
            kontakt
          </Link>
        </div>
      </div>
    </header>
  );
};
