import { css } from '@emotion/react';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { breakpoints, colors } from 'style/variables';

const root = css`
  width: 100%;
  background-color: ${colors.backgroundLight};
  padding: calc(var(--unit) * 9) 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 10px;
  justify-items: center;
  align-items: center;

  @media ${breakpoints.contact} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const email = css`
  color: ${colors.primary};
  text-decoration: underline;
  display: inline-block;
`;

const descriptionContainer = css`
  ${font.size.base}
  width: 200px;
`;

const socialContent = css`
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const socialIcon = css`
  width: 40px;
  height: 40px;
  fill: ${colors.primary};
  margin-right: 10px;
`;

const infoContainer = css`
  width: 200px;
`;

export const footerStyles = {
  root,
  email,
  descriptionContainer,
  socialContent,
  socialIcon,
  infoContainer,
};
